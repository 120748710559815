import axios from "axios";
import { Message } from "element-ui";
import router from '../router/index';
import qs from "qs";

const instance = axios.create({
  timeout: 30000,
  withCredentials: true,
  // baseURL: "https://jxstreet.wxactech.com",
  headers: {
    "responseType": 'blob',
    "Content-Type":
      "application/json; charset=utf-8;multipart/form-data; boundary=something",
    'token': localStorage.getItem("bigdatatoken") || ""
  },
});

//定义一个请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log(config)
    // if(config.url=="")
    // console.log(JSON.stringify(config))
    config.headers["responseType"] = 'blob',
      config.headers["token"] = localStorage.getItem("bigdatatoken") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//定义一个响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(response.data.heads.code==301){
      router.push({ path: "/login" }); 
      Message.error('请重新登录')
    }  
    if (response.status == 201) {
      return res;
    }
    if (res.heads.code == 200) {
      return res;
    }
    if (res.heads.code == 300) {
      Message.error(res.heads.message);
    }
  },
  (error) => {
    switch (error.response.status) {
      case 400:
        Message.error("请求失败400", 3);
        break;
      case 300:
        Message.error("请求失败300", 3);
        break;
      default:
        Message.error("请求失败", 3);
        break;
    }
  }
);

export function newRequest(params) {
  if (params.method == "post") {
    return instance({
      data: JSON.stringify(params.data),
      method: params.method,
      url: params.url,
      responseType: params.responseType ? 'blob' : "",
      // showLoading: params.showLoading === undefined ? true : params.showLoading,
    });
  } else {
    return instance({
      method: params.method,
      url:
        JSON.stringify(params.data) == "{}"
          ? params.url
          : params.url + "?" + qs.stringify(params.data),
      responseType: params.responseType ? 'blob' : "",
      // showLoading: params.showLoading === undefined ? true : params.showLoading,
    });
  }
}
